import React from "react";
import logo from '../../../assets/img/wa-logo-color.png';
import { Nav, NavItem, NavLink, Col, Row } from "reactstrap";

const AppFooter = () => {
    const currentYear: number = new Date().getFullYear();

    return (
        <div className="container-fluid">
        <footer className="footer">
            <Row className="align-items-center justify-content-xl-between">
                <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                    <a
                        className="font-weight-bold ml-1"
                        href="https://maciejwasiak.com"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={logo} alt="WebAnalytics" height="18" />
                    </a>
                    &nbsp; &copy;  &nbsp;{currentYear}
                </div>
                </Col>
                <Col xl="6">
                    <Nav className="nav-footer justify-content-center justify-content-xl-end">
                        <NavItem>
                            <NavLink href="mailto:hello@maciejwasiak.com">
                                Contact
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>
        </footer>
        </div>
    );
}

export default AppFooter;