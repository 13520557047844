export class CacheData {
    created: number = 0;
    data: any;

    constructor(created: number, data: any) {
        this.created = created;
        this.data = data;
    }
}

export default class CacheService {

    private prefix: string = 'wa_';
    private duration: number = 60; // s

    check(index: string) {
        let rawData = localStorage.getItem(this.getIndex(index));

        return (index.length && rawData !== null && this.valid(this.toJson(rawData)));
    }

    valid(cachedData: CacheData) {
        return (Date.now() - cachedData.created) >= this.duration;
    }

    get(index: string) {
        let rawData = localStorage.getItem(this.getIndex(index));

        if(!rawData) {
            return null;
        }
        
        let cachedData: CacheData = this.toJson(rawData);

        if(!this.valid(cachedData)) {
            this.del(index);
            return null;
        }
        return cachedData.data;
    }

    set(index: string, value: any) {
        if(index.length && value) {
            localStorage.setItem(this.getIndex(index), this.toString(new CacheData(Date.now(), value)));
        }
    }

    update(index: string, value: any) {
        if(this.check(index)) {
            localStorage.setItem(this.getIndex(index), this.toString(new CacheData(Date.now(), value)));
        }
    }

    del(index: string) {
        if(this.check(index)) {
            localStorage.removeItem(this.getIndex(index));
        }
    }

    private toJson(value: string) {
        return JSON.parse(value);
    }

    private toString(object: object): string {
        return JSON.stringify(object);
    }

    private getIndex(index: string): string {
        return this.prefix + index;
    }
}