//import { KeyboardEvent, MouseEvent } from "react";
import CacheService from "./Cache";
import Logger from "./Logger";
import { ClickAggregator } from "../aggregators/Click";
import { KeyAggregator } from "../aggregators/Key";
import {
  EnvDataDetector,
  envDefaultValue,
  IDetectedEnvData,
} from "../plugins/EnvDataDetector";
import { DataSender, FrameType, HttpMethodEnum } from "./DataSender";
import { DateTimeUtils } from "../utils/DateTime";
import crypto from "crypto";

export enum EventType {
  generic = "generic",
  custom = "custom",
  mouse = "mouse",
  keyboard = "keyboard",
}

export enum EventName {
  click = "click",
  keyup = "keyup",
  word = "word",
  move = "mousemove",
}

export class EventData {
  detectedOn: Date;
  type: EventType;
  name: string;
  x?: number;
  y?: number;
  key?: string;
  keyCode?: string;
  data?: any;

  constructor(
    detectedOn: Date,
    type: EventType,
    name: string,
    x?: number,
    y?: number,
    key?: string,
    keyCode?: string,
    data?: any
  ) {
    this.detectedOn = detectedOn;
    this.type = type;
    this.name = name;
    this.x = x;
    this.y = y;
    this.key = key;
    this.keyCode = keyCode;
    this.data = data;
  }
}

export interface IGeoLocationData {
  country: string;
  countryCode: string;
  region: string;
  city: string;
  zip: string;
  lat: number;
  lon: number;
  isp: string;
  ip: string;
}

export class EnvData {
  deviceType: string;
  deviceName: string;
  deviceOS: string;
  browserName: string;
  browserVersion: string;
  screenWidth: number;
  screenHeight: number;
  // GeoLocation data
  glocCountry?: string;
  glocCountryCode?: string;
  glocRegion?: string;
  glocCity?: string;
  glocPostal?: string;
  glocLat?: number;
  glocLon?: number;
  glocIsp?: string;
  glocIp?: string;

  constructor(
    deviceType: string,
    deviceName: string,
    deviceOS: string,
    browserName: string,
    browserVersion: string,
    screenWidth: number,
    screenHeight: number
  ) {
    this.deviceType = deviceType;
    this.deviceName = deviceName;
    this.deviceOS = deviceOS;
    this.browserName = browserName;
    this.browserVersion = browserVersion;
    this.screenWidth = screenWidth;
    this.screenHeight = screenHeight;
  }
}

export interface IAggregator<T> {
  aggregate: (e: EventData) => T;
  getAggregatedData: () => T;
}

export interface IExtractorAggregatedData {
  client: string;
  envData: EnvData;
  envDataAggr: {
    firstTime: string; // ISO string
    lastTime: string; // ISO string
    elapsed: number; // s
  };
  clickCount: number;
  cpm: number[];
  avgCpm: number[];
  keyCount: number;
  kpm: number[];
  avgKpm: number[];
  words: string[];
  sendDataHistory: any[];
}

export const defaultGeoLocationData = {
  country: envDefaultValue,
  countryCode: envDefaultValue,
  region: envDefaultValue,
  city: envDefaultValue,
  zip: envDefaultValue,
  lat: 0,
  lon: 0,
  isp: envDefaultValue,
  ip: envDefaultValue,
};

export const defaultAggregatedData = {
  client: envDefaultValue,
  envData: {
    deviceType: "",
    deviceName: "",
    deviceOS: "",
    browserName: "",
    browserVersion: "",
    screenWidth: 0,
    screenHeight: 0,
  },
  envDataAggr: {
    firstTime: "",
    lastTime: "",
    elapsed: 0,
  },
  clickCount: 0,
  cpm: [],
  avgCpm: [],
  keyCount: 0,
  kpm: [],
  avgKpm: [],
  words: [],
  sendDataHistory: [],
};

export class Extractor {
  public client: string;
  public geoLocationData: IGeoLocationData;
  public aggregatedData: IExtractorAggregatedData;

  private cacheEventHistoryIndex = "eventHistory";
  private eventBuffer: EventData[] = [];
  private sendDataFreq: number = 60; // s
  private sendDataIntervalId: any;
  private dataLimit: number = 10;

  private cache: CacheService;
  private clickAggregator: ClickAggregator;
  private keyAggregator: KeyAggregator;
  private dataSender: DataSender;
  private envDetector: EnvDataDetector;

  constructor() {
    this.cache = new CacheService();
    this.clickAggregator = new ClickAggregator();
    this.keyAggregator = new KeyAggregator();
    this.dataSender = new DataSender();
    this.envDetector = new EnvDataDetector();

    this.client = "unknown";
    this.geoLocationData = defaultGeoLocationData;
    this.aggregatedData = defaultAggregatedData;
    this.aggregatedData.sendDataHistory = [];

    this.sendData = this.sendData.bind(this);

    this.sendDataIntervalId = setInterval(
      this.sendData,
      this.sendDataFreq * 1000
    );
  }

  getEventHistory() {
    return this.eventBuffer;
  }

  calculateClientHash(): string {
    const nav = window.navigator;
    //const screen = window.screen;
    const ipNumber: number = this.geoLocationData.ip
      ? this.geoLocationData.ip.split(".").reduce(function (ipInt, octet) {
          return (ipInt << 8) + parseInt(octet, 10);
        }, 0) >>> 0
      : 0;
    const uaString: string = nav.userAgent.replace(/\D+/g, "");
    const device = this.aggregatedData.envData.deviceName;
    const type = this.aggregatedData.envData.deviceType;
    const os = this.aggregatedData.envData.deviceOS;

    const clientHash = crypto
      .createHash("sha256")
      .update(`${ipNumber}${uaString}${device}${type}${os}`)
      .digest("hex");

    Logger.log("[Extractor]> Calculated client: ", clientHash);

    return clientHash;
  }

  async getGeoLocation(): Promise<IGeoLocationData> {
    Logger.log("[Extractor]> retrieving geolocation data ...");

    const rawData = await this.dataSender.getGeoLocationData();

    if (!rawData) {
      return this.geoLocationData;
    }

    const geoLocationData: IGeoLocationData = {
      country: rawData.country,
      countryCode: rawData.countryCode,
      region: rawData.region,
      city: rawData.city,
      zip: rawData.zip,
      lat: rawData.lat,
      lon: rawData.lon,
      isp: rawData.isp,
      ip: rawData.ip,
    };

    this.geoLocationData = geoLocationData;

    Logger.log(
      `[Extractor]> completed. IP: ${geoLocationData.ip} ${geoLocationData.countryCode}`
    );

    this.aggregatedData.sendDataHistory.push({
      method: HttpMethodEnum.GET,
      time: DateTimeUtils.getTimeDate(),
      type: FrameType.geoLoc,
      count: 1,
    });

    return geoLocationData;
  }

  async extractEnvData(): Promise<void> {
    const envData: IDetectedEnvData = this.envDetector.detect();
    const geoLocationData: IGeoLocationData = await this.getGeoLocation();

    this.aggregatedData.envData.deviceType = envData.device.type;
    this.aggregatedData.envData.deviceName = envData.device.name;
    this.aggregatedData.envData.deviceOS = envData.device.os;
    this.aggregatedData.envData.browserName = envData.browser.name;
    this.aggregatedData.envData.browserVersion = envData.browser.version;
    this.aggregatedData.envData.screenWidth = envData.screen.width;
    this.aggregatedData.envData.screenHeight = envData.screen.height;

    if (geoLocationData) {
      this.aggregatedData.envData.glocCountry = geoLocationData.country;
      this.aggregatedData.envData.glocCountryCode = geoLocationData.countryCode;
      this.aggregatedData.envData.glocRegion = geoLocationData.region;
      this.aggregatedData.envData.glocCity = geoLocationData.city;
      this.aggregatedData.envData.glocPostal = geoLocationData.zip;
      this.aggregatedData.envData.glocLat = geoLocationData.lat;
      this.aggregatedData.envData.glocLon = geoLocationData.lon;
      this.aggregatedData.envData.glocIsp = geoLocationData.isp;
      this.aggregatedData.envData.glocIp = geoLocationData.ip;
    }

    this.client = this.calculateClientHash();
    this.aggregatedData.client = this.client;

    this.aggregateEnvDataForClient(this.client);

    Logger.log("[Extractor]> sending env data to server...");

    this.dataSender
      .sendEnvData({ client: this.client, ...this.aggregatedData.envData })
      .then((result: any) => {
        Logger.log(`[Extractor]> completed. result: ${result}`);

        if (result) {
          this.aggregatedData.sendDataHistory.push({
            method: HttpMethodEnum.POST,
            time: DateTimeUtils.getTimeDate(),
            type: FrameType.env,
            count: 1,
          });
        }
      });
  }

  aggregateEnvDataForClient(client: string) {
    if (!client) return;

    Logger.log("[Extractor]> aggregating env data for client...");

    this.dataSender.getEnvDataForClient(client).then((result) => {
      Logger.log(`[Extractor]> completed. result:`, result);

      if (result) {
        this.aggregatedData.client = client;
        this.aggregatedData.envDataAggr.firstTime = new Date(
          result.received / 1000
        ).toISOString();
        this.aggregatedData.envDataAggr.lastTime = new Date(
          result.modifiedOn
        ).toISOString();
        this.aggregatedData.envDataAggr.elapsed = Math.round(
          (new Date().getTime() - result.received) / 1000
        );
      }

      this.aggregatedData.sendDataHistory.push({
        method: HttpMethodEnum.GET,
        time: DateTimeUtils.getTimeDate(),
        type: FrameType.env,
        count: 1,
      });
    });
  }

  extractMouseEventData(event: MouseEvent) {
    const eventData: EventData = new EventData(
      new Date(),
      EventType.mouse,
      event.type
    );

    eventData.x = event.clientX;
    eventData.y = event.clientY;

    Logger.log(`[Extractor]> extracted ${EventType.mouse} event data`);

    this.collectEvent(eventData);

    this.aggregateEvent(eventData);

    return eventData;
  }

  extractKeyEventData(event: KeyboardEvent) {
    const eventData: EventData = new EventData(
      new Date(),
      EventType.keyboard,
      event.type
    );

    eventData.key = event.key;
    eventData.keyCode = event.code;

    Logger.log(`[Extractor]> extracted ${EventType.keyboard} event data`);

    this.collectEvent(eventData);

    this.aggregateEvent(eventData);

    return eventData;
  }

  extractCustomEventData(event: CustomEvent) {
    const eventData: EventData = new EventData(
      new Date(),
      EventType.custom,
      event.type
    );

    Logger.log(`[Extractor]> extracted ${EventType.custom} event data`);

    switch (event.type) {
      case EventName.word:
        eventData.data = this.aggregatedData.words[
          this.aggregatedData.words.length - 1
        ] as string;
        break;
    }

    this.collectEvent(eventData);

    return eventData;
  }

  collectEvent(eventData: EventData) {
    this.eventBuffer.push(eventData);

    if (this.eventBuffer.length >= this.dataLimit) {
      this.sendData();
    }
  }

  aggregateEvent(event: EventData) {
    Logger.log(
      `[Extractor]> aggregating ${event.type} ${event.name} event data...`
    );

    if (!event.type) {
      return;
    }

    switch (event.type) {
      case EventType.mouse:
        this.aggregateMouseEvent(event);
        break;
      case EventType.keyboard:
        this.aggregateKeyEvent(event);
        break;
    }

    Logger.log("[Extractor]> aggregatedData:", this.aggregatedData);
  }

  saveData() {
    const buffer = this.eventBuffer;

    if (!buffer.length) {
      return;
    }

    if (this.cache.check(this.cacheEventHistoryIndex)) {
      let cachedEventHistory = this.cache.get(this.cacheEventHistoryIndex);

      buffer.forEach(function (v) {
        cachedEventHistory.push(v);
      });

      this.cache.update(this.cacheEventHistoryIndex, cachedEventHistory);
      Logger.log("[Extractor]> data updated in cache");
    } else {
      this.cache.set(this.cacheEventHistoryIndex, buffer);
      Logger.log("[Extractor]> data added to cache");
    }
  }

  sendData() {
    const clientIp = this.aggregatedData.envData.glocIp
      ? this.aggregatedData.envData.glocIp
      : envDefaultValue;
    const buffer = this.eventBuffer;

    if (!buffer.length) {
      return;
    }

    Logger.log("[Extractor]> sending data to server...");

    this.dataSender
      .sendRawData({
        client: this.client,
        ip: clientIp,
        data: {
          cpm: this.aggregatedData.cpm.pop(),
          avgCpm: this.aggregatedData.avgCpm.pop(),
          kpm: this.aggregatedData.kpm.pop(),
          avgKpm: this.aggregatedData.avgKpm.pop(),
        },
      })
      .then((result: any) => {
        Logger.log(`[Extractor]> completed. result: ${result}`);

        if (result) {
          this.eventBuffer = [];
          this.aggregatedData.sendDataHistory.push({
            method: HttpMethodEnum.POST,
            time: DateTimeUtils.getTimeDate(),
            type: FrameType.raw,
            count: 1,
          });
        }
      });

    this.saveData();

    this.eventBuffer = [];
  }

  private aggregateMouseEvent(event: EventData) {
    switch (event.name) {
      case EventName.click:
        const aggregated = this.clickAggregator.aggregate(event);

        this.aggregatedData.clickCount = aggregated.count;
        this.aggregatedData.cpm = aggregated.cpm;
        this.aggregatedData.avgCpm = aggregated.avgCpm;
        break;
    }
  }

  private aggregateKeyEvent(event: EventData) {
    switch (event.name) {
      case EventName.keyup:
        const aggregated = this.keyAggregator.aggregate(event);

        this.aggregatedData.keyCount = aggregated.count;
        this.aggregatedData.kpm = aggregated.kpm;
        this.aggregatedData.avgKpm = aggregated.avgKpm;
        this.aggregatedData.words = aggregated.words;
        break;
      case EventName.word:
        const aggregatedData = this.keyAggregator.getAggregatedData();

        this.aggregatedData.words = aggregatedData.words;
        break;
    }
  }
}
