import React, { ReactElement } from "react";
import { envDefaultValue } from "../../plugins/EnvDataDetector";
import {
  EnvData,
  EventData,
  EventName,
  Extractor,
} from "../../services/Extractor";
import Logger from "../../services/Logger";

type TrackerProps = {};

export type TrackerState = {
  client: string;
  envData: EnvData;
  envDataAggr: {
    firstTime: string; // ISO string
    lastTime: string; // ISO string
    elapsed: number; // s
  };
  timeSpent: number;
  clickCount: number;
  cpm: number;
  avgCpm: number;
  letterCount: number;
  kpm: number;
  avgKpm: number;
  words: string[];
  eventHistory: EventData[];
  sendDataHistory: any[];
};

export const defaultTrackerState: TrackerState = {
  client: envDefaultValue,
  envData: {
    deviceType: envDefaultValue,
    deviceName: envDefaultValue,
    deviceOS: envDefaultValue,
    browserName: envDefaultValue,
    browserVersion: envDefaultValue,
    screenWidth: 0,
    screenHeight: 0,
  },
  envDataAggr: {
    firstTime: envDefaultValue,
    lastTime: envDefaultValue,
    elapsed: 0,
  },
  timeSpent: 0,
  clickCount: 0,
  cpm: 0,
  avgCpm: 0,
  letterCount: 0,
  kpm: 0,
  avgKpm: 0,
  words: [],
  eventHistory: [],
  sendDataHistory: [],
};

class Tracker extends React.Component<TrackerProps, TrackerState> {
  private extractor: Extractor;
  private readonly startDate: number;
  private timeSpentIntervalId: any;
  private timeSpentInterval: number;
  private eventHistoryLimit: number;

  constructor(props: any) {
    super(props);

    this.extractor = new Extractor();

    this.startDate = Date.now();
    this.state = defaultTrackerState;
    this.timeSpentInterval = 1000;
    this.eventHistoryLimit = 5;

    this.trackEnvData = this.trackEnvData.bind(this);
    this.trackMouseEvent = this.trackMouseEvent.bind(this);
    this.trackKeyEvent = this.trackKeyEvent.bind(this);
    this.runJobs = this.runJobs.bind(this);
  }

  componentDidMount() {
    this.subscribeToEvents();

    this.trackEnvData();

    this.timeSpentIntervalId = setInterval(
      this.runJobs,
      this.timeSpentInterval
    );

    Logger.log("[Tracker]> event tracking started ... ");
  }

  componentWillUnmount() {
    this.unsubscribeToEvents();

    clearInterval(this.timeSpentIntervalId);

    Logger.log("[Tracker]> event tracking stopped. Bye!");
  }

  subscribeToEvents() {
    window.addEventListener(EventName.click, this.trackMouseEvent);
    window.addEventListener(EventName.keyup, this.trackKeyEvent);
    window.addEventListener(EventName.word, ((event: CustomEvent) => {
      this.trackCustomEvent(event);
    }) as EventListener);

    //window.addEventListener("resize", this.extractor.extractEnvData;
  }

  unsubscribeToEvents() {
    window.removeEventListener(EventName.click, this.trackMouseEvent);
    window.removeEventListener(EventName.keyup, this.trackKeyEvent);
    window.removeEventListener(EventName.word, ((event: CustomEvent) => {
      this.trackCustomEvent(event);
    }) as EventListener);
  }

  async trackEnvData() {
    await this.extractor.extractEnvData();
    this.setState({
      client: this.extractor.aggregatedData.client,
      envData: this.extractor.aggregatedData.envData,
      envDataAggr: this.extractor.aggregatedData.envDataAggr,
    });
  }

  trackMouseEvent(event: MouseEvent) {
    event.preventDefault();
    Logger.log(
      `[Tracker]> tracked mouse event ${event.type} @${event.screenX}x${event.screenY}`
    );

    const eventData = this.extractor.extractMouseEventData(event);

    const cpmArray = this.extractor.aggregatedData.cpm;
    const avgCpmArray = this.extractor.aggregatedData.avgCpm;
    const lastCpm = cpmArray[cpmArray.length - 1] || 0;
    const lastAvgCpm = avgCpmArray[avgCpmArray.length - 1] || 0;

    this.logEvent(eventData);

    this.setState({
      clickCount: this.extractor.aggregatedData.clickCount,
      cpm: lastCpm,
      avgCpm: lastAvgCpm,
    });
  }

  trackKeyEvent(event: KeyboardEvent) {
    event.preventDefault();
    Logger.log(
      `[Tracker]> tracked keyboard event ${event.type} key: ${event.key} code: ${event.code}`
    );

    const eventData = this.extractor.extractKeyEventData(event);

    const kpmArray = this.extractor.aggregatedData.kpm;
    const avgKpmArray = this.extractor.aggregatedData.avgKpm;
    const lastKpm = kpmArray[kpmArray.length - 1] || 0;
    const lastAvgKpm = avgKpmArray[avgKpmArray.length - 1] || 0;

    this.logEvent(eventData);

    this.setState({
      letterCount: this.extractor.aggregatedData.keyCount,
      kpm: lastKpm,
      avgKpm: lastAvgKpm,
      words: this.extractor.aggregatedData.words,
    });
  }

  trackCustomEvent(event: CustomEvent) {
    event.preventDefault();
    Logger.log(
      `[Tracker]> tracked custom event ${event.type} type: ${event.type}`
    );

    const eventData = this.extractor.extractCustomEventData(event);

    this.logEvent(eventData);
  }

  trackTimeSpent() {
    let timeSpend = Date.now() - this.startDate;

    this.setState({
      timeSpent: timeSpend / 1000,
    });
  }

  runJobs() {
    this.trackTimeSpent();
  }

  logEvent(event: EventData) {
    let eventHistory = this.state.eventHistory;

    eventHistory.push(event);

    if (eventHistory.length > this.eventHistoryLimit) {
      eventHistory.shift();
    }

    this.setState({
      eventHistory: eventHistory,
      sendDataHistory: this.extractor.aggregatedData.sendDataHistory,
    });
  }

  render() {
    const childrenWithTrackedData = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as ReactElement<any>, {
            trackerState: this.state,
          });
        }
      }
    );

    return <div className="main-content">{childrenWithTrackedData}</div>;
  }
}
export default Tracker;
