import { EventData, IAggregator } from "../services/Extractor";
import Logger from "../services/Logger";
import { MathUtils } from "../utils/Math";

export interface IClickAggregatedData {
    count: number,
    cpm: number[];
    avgCpm: number[];
}

export const defaultClickAggregatedData = {
    count: 0,
    cpm: [],
    avgCpm: []
};

export class ClickAggregator implements IAggregator<IClickAggregatedData> {
    private cpmFreq: number;
    private cpmCounter: number;
    private eventTime: number;
    private aggregatedData: IClickAggregatedData;


    constructor() {
        this.cpmFreq = 60;
        this.cpmCounter = 0;
        this.eventTime = new Date().getTime();
        this.aggregatedData = defaultClickAggregatedData;
    }

    aggregate(event: EventData): IClickAggregatedData {
        let eventTime = event.detectedOn.getTime();

        this.aggregateCount();

        this.aggregateCpm(eventTime);

        return this.aggregatedData;
    }

    getAggregatedData(): IClickAggregatedData {
        return this.aggregatedData;
    }

    private aggregateCount() {
        this.aggregatedData.count++;
    }

    private aggregateCpm(eventTime: number) {
        const firstClick = this.eventTime === 0;

        if(firstClick) {
            this.eventTime = eventTime;
        }

        this.cpmCounter++;

        const period = (eventTime - this.eventTime)/1000;
        const cpm = this.calcCpm(this.cpmCounter, period);

        Logger.log(`[ClickAggregator]> aggregating ${this.cpmCounter}clicks/${period}s ... -> cpm: ${cpm}`);

        this.aggregatedData.cpm.push(cpm);
        this.cpmCounter = 0;
        this.eventTime = eventTime;

        this.aggregateAvgCpm();
    }

    private aggregateAvgCpm() {
        let avgCpm = this.calcAvgCpm();

        this.aggregatedData.avgCpm.push(avgCpm);
    }

    private calcCpm(count: number, period: number) {
        const minutes = period/this.cpmFreq;
        const cpm = MathUtils.round(count/minutes, 2);

        return cpm;
    }

    private calcAvgCpm() {
        let cpmSum: number = 0;
        this.aggregatedData.cpm.forEach(function (cpm) {
            cpmSum += cpm;
        });

        let avgCpm = MathUtils.round(cpmSum/this.aggregatedData.cpm.length, 2);

        return avgCpm;
    }
}