import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Sparklines, SparklinesLine } from "react-sparklines";

import { EventData, EventType } from "../../services/Extractor";
import { TrackerState } from "../tracker/Tracker";

//import Logger from "../../services/Logger";

export type DashboardProps = {
  trackerState: TrackerState;
};
export type DashboardState = {
  trackerState: TrackerState;
  cpmHistory: number[];
  avgCpmHistory: number[];
  kpmHistory: number[];
  avgKpmHistory: number[];
};

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      trackerState: this.props.trackerState,
      cpmHistory: [],
      avgCpmHistory: [],
      kpmHistory: [],
      avgKpmHistory: [],
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      const cpmHistory = this.logStatData(
        this.state.cpmHistory,
        this.props.trackerState.cpm
      );
      const avgCpmHistory = this.logStatData(
        this.state.avgCpmHistory,
        this.props.trackerState.avgCpm
      );
      const kpmHistory = this.logStatData(
        this.state.kpmHistory,
        this.props.trackerState.kpm
      );
      const avgKpmHistory = this.logStatData(
        this.state.avgKpmHistory,
        this.props.trackerState.avgKpm
      );

      this.setState({
        trackerState: this.props.trackerState,
        cpmHistory: cpmHistory,
        avgCpmHistory: avgCpmHistory,
        kpmHistory: kpmHistory,
        avgKpmHistory: avgKpmHistory,
      });
    }
  }

  private logStatData(collection: number[], value: number): number[] {
    const lastValue = collection[collection.length - 1];

    if (value !== lastValue) {
      collection.push(value);
    }

    return collection;
  }

  private renderGreet() {
    const elapsed = this.state.trackerState.envDataAggr.elapsed;
    return elapsed && elapsed > 0
      ? "Welcome back! :)"
      : "Ohh! New user, welcome! :)";
  }

  private renderTimeSpent() {
    let timeSpent = this.state.trackerState.timeSpent;
    return timeSpent
      ? new Date(timeSpent * 1000).toISOString().substring(11, 19)
      : "";
  }

  private renderEventInfo(event: EventData) {
    let eventInfo: string = "";
    switch (event.type) {
      case EventType.mouse:
        eventInfo = `Position: ${event.x}x${event.y}px`;
        break;
      case EventType.keyboard:
        eventInfo = `Key: ${event.key} Code: ${event.keyCode}`;
        break;
      case EventType.custom:
        eventInfo = `Data: ${event.data}`;
        break;
    }

    return eventInfo;
  }

  render() {
    return (
      <>
        {this.state.trackerState.envDataAggr && (
          <div className="header bg-gradient-info pb-1 pt-1 pt-md-6 pt-sm-6">
            <Container className="d-flex align-items-center" fluid>
              <Row>
                <Col>
                  <h2 className="display-4 text-white">{this.renderGreet()}</h2>
                  <p className="text-white mt-0 mb-0">
                    Your client id:{" "}
                    <strong>{this.state.trackerState.client}</strong>
                  </p>
                  <p className="text-white mt-0 mb-0">
                    We started collecting your data at{" "}
                    <strong>
                      {this.state.trackerState.envDataAggr.firstTime}
                    </strong>
                  </p>
                  <p className="text-white mt-0 mb-5">
                    We received last data from you at{" "}
                    <strong>
                      {this.state.trackerState.envDataAggr.lastTime}
                    </strong>
                  </p>
                  <Button color="primary" onClick={() => {}}>
                    Engage!
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="4" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Time spend
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.renderTimeSpent()}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-clock" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last session</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Clicks count
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.trackerState.clickCount}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-mouse-pointer" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last session</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            CPM
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.trackerState.cpm}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-mouse-pointer" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 1.08%
                        </span>{" "}
                        <span className="text-nowrap">Since last session</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Avg CPM
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.trackerState.avgCpm}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-mouse-pointer" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 1.08%
                        </span>{" "}
                        <span className="text-nowrap">Since last session</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Avg KPM
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.trackerState.avgKpm}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-font" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 1.08%
                        </span>{" "}
                        <span className="text-nowrap">Since last session</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Words count
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.trackerState.words.length}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-keyboard" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 1.08%
                        </span>{" "}
                        <span className="text-nowrap">Since last session</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7 pb-4 bg-gradient-info" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow" style={{ minHeight: "365px" }}>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Activity log</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  hover
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Detection time</th>
                      <th scope="col">Event type</th>
                      <th scope="col">Event name</th>
                      <th scope="col">Additional info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.trackerState.eventHistory.map(
                      (event: EventData, idx: number) => {
                        return (
                          <tr key={`eventHistoryRow${idx}`}>
                            <th>{event.detectedOn.toISOString()}</th>
                            <td>{event.type}</td>
                            <td>{event.name}</td>
                            <td>{this.renderEventInfo(event)}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Aggregation log</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Aggregator</th>
                      <th scope="col">Current value</th>
                      <th scope="col">Progress</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">CPM</th>
                      <td>{this.state.trackerState.cpm}</td>
                      <td>
                        <Sparklines
                          data={this.state.cpmHistory}
                          limit={5}
                          height={20}
                        >
                          <SparklinesLine color="blue" />
                        </Sparklines>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">AvgCPM</th>
                      <td>{this.state.trackerState.avgCpm}</td>
                      <td>
                        <Sparklines
                          data={this.state.avgCpmHistory}
                          limit={5}
                          height={20}
                        >
                          <SparklinesLine color="blueviolet" />
                        </Sparklines>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">KPM</th>
                      <td>{this.state.trackerState.kpm}</td>
                      <td>
                        <Sparklines
                          data={this.state.kpmHistory}
                          limit={5}
                          height={20}
                        >
                          <SparklinesLine color="limegreen" />
                        </Sparklines>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">AvgKPM</th>
                      <td>{this.state.trackerState.avgKpm}</td>
                      <td>
                        <Sparklines
                          data={this.state.avgKpmHistory}
                          limit={5}
                          height={20}
                        >
                          <SparklinesLine color="darkgreen" />
                        </Sparklines>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Words</th>
                      <td>{this.state.trackerState.words.length}/100</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">
                            {this.state.trackerState.words.length}%
                          </span>
                          <div>
                            <Progress
                              max="100"
                              value={this.state.trackerState.words.length}
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Send data log</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  hover
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Send time</th>
                      <th scope="col">Method</th>
                      <th scope="col">Frame type</th>
                      <th scope="col">Data count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.trackerState.sendDataHistory.map(
                      (frame: any, idx: number) => {
                        return (
                          <tr key={`sendDataHistoryRow${idx}`}>
                            <th>{frame.time}</th>
                            <th>{frame.method}</th>
                            <td>{frame.type}</td>
                            <td>{frame.count}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
