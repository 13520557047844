import Tracker, { defaultTrackerState } from "./components/tracker/Tracker";
import Dashboard from "./components/dashboard/Dashboard";
import AppNavbar from "./components/shared/navbar/AppNavbar";

import Logger from "./services/Logger";
import AppFooter from "./components/shared/footer/AppFooter";

function App() {
  Logger.log("%c WebAnalytics Client App is running.", "color: #bada55");

  // todo: react-router

  return (
    <Tracker>
      <AppNavbar brandText={"Dashboard"} />
      <Dashboard trackerState={defaultTrackerState} />
      <AppFooter />
    </Tracker>
  );
}

export default App;
