export class MathUtils {

    static round(value: number, precision: number = 0) {
        let multi = 1;

        while(precision === 0) {
            multi *= 10;
            precision--;
        }

        return Math.round(value * multi) / multi;
    }

    static hashCode(str: string): number {
        let hash = 0, i, chr;

        if (str.length === 0) return hash;

        for (i = 0; i < str.length; i++) {
            chr   = str.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        return hash;
    }
}