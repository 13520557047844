import CacheService from "./Cache";
import Logger from "./Logger";

export interface IAuthToken {
  expiresIn: number;
  value: string;
}

export interface IEnvDataFrame {
  readonly client: string;
  readonly deviceType: string;
  readonly deviceName: string;
  readonly deviceOS: string;
  readonly browserName: string;
  readonly browserVersion: string;
  readonly screenWidth: number;
  readonly screenHeight: number;
  // GeoLocation data
  readonly glocCountry?: string;
  readonly glocCountryCode?: string;
  readonly glocRegion?: string;
  readonly glocCity?: string;
  readonly glocPostal?: string;
  readonly glocLat?: number;
  readonly glocLon?: number;
  readonly glocIsp?: string;
  readonly glocIp?: string;
}

export interface IEnvData {
  readonly envId: number;
  readonly client: string;
  readonly received: number;
  readonly ip: string;
  readonly deviceType: string;
  readonly deviceName: string;
  readonly deviceOS: string;
  readonly browserName: string;
  readonly browserVersion: string;
  readonly screenWidth: number;
  readonly screenHeight: number;
  readonly modifiedOn: Date;
  readonly modifiedBy: string;
}

export interface IRawDataFrame {
  readonly client: string;
  readonly ip: string;
  readonly data: Object; // { cmp, cmpAvg, speedAvg }
}

export enum FrameType {
  env = "Env",
  raw = "Raw",
  geoLoc = "GeoLoc",
}

export enum HttpMethodEnum {
  GET = "get",
  POST = "post",
}

export class DataSender {
  private authToken: IAuthToken;
  private authTokenExpiration: number;
  private readonly cacheAccessTokenIndex = "accessToken";
  private readonly apiUrl: string;

  private cache: CacheService;

  constructor() {
    this.authToken = { expiresIn: 0, value: "" };
    this.authTokenExpiration = 1800; // s
    this.apiUrl = `${process.env.REACT_APP_WA_SERVER_URL}`;
    this.cache = new CacheService();
  }

  async checkToken() {
    const cachedAuthToken = this.cache.get(this.cacheAccessTokenIndex);

    if (cachedAuthToken) {
      this.authToken = cachedAuthToken;
    }

    return this.authToken.expiresIn > new Date().getTime()
      ? this.authToken
      : await this.auth();
  }

  async auth() {
    try {
      const response = await fetch(`${this.apiUrl}/auth/login`, {
        method: HttpMethodEnum.POST,
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          username: process.env.REACT_APP_WA_SERVER_LOGIN,
          password: process.env.REACT_APP_WA_SERVER_PASS,
        }),
      });

      const data = await response.json();

      this.authToken.value = data.access_token;
      this.authToken.expiresIn =
        new Date().getTime() + this.authTokenExpiration * 1000;

      this.cache.set(this.cacheAccessTokenIndex, this.authToken);

      return this.authToken;
    } catch (ex) {
      return false;
    }
  }

  async getEnvDataForClient(client: string): Promise<IEnvData | false> {
    await this.checkToken();
    try {
      const response = await fetch(`${this.apiUrl}/data/env/${client}`, {
        method: HttpMethodEnum.GET,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${this.authToken.value}`,
        }),
      });
      const body: IEnvData = await response.json();
      return body;
    } catch (ex) {
      return false;
    }
  }

  async sendEnvData(data: IEnvDataFrame) {
    await this.checkToken(); // check/get authToken

    try {
      const response = await fetch(`${this.apiUrl}/data/env`, {
        method: HttpMethodEnum.POST,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${this.authToken.value}`,
        }),
        body: JSON.stringify(data),
      });
      return response.ok;
    } catch (ex) {
      return false;
    }
  }

  async sendRawData(data: IRawDataFrame) {
    await this.checkToken(); // check/get authToken

    try {
      const response = await fetch(`${this.apiUrl}/data/raw`, {
        method: HttpMethodEnum.POST,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${this.authToken.value}`,
        }),
        body: JSON.stringify(data),
      });
      return response.ok;
    } catch (ex) {
      return false;
    }
  }

  async getGeoLocationData() {
    try {
      const response = await fetch(`${process.env.REACT_APP_GEOLOCATION_URL}`, {
        method: HttpMethodEnum.GET,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      });

      const rawData = await response.json();

      return {
        country: rawData.country_name,
        countryCode: rawData.country_code,
        region: rawData.region,
        city: rawData.city,
        zip: rawData.postal,
        lat: rawData.latitude,
        lon: rawData.longitude,
        isp: "",
        ip: rawData.ip,
      };
    } catch (ex) {
      Logger.error("DataSender > getGeoLocationData() error:", ex);
      return false;
    }
  }
}
