import { EventData, IAggregator } from "../services/Extractor";
import Logger from "../services/Logger";
import { MathUtils } from "../utils/Math";

export interface IKeyAggregatedData {
    count: number,
    kpm: number[];
    avgKpm: number[];
    words: string[]
}

export const defaultKeyAggregatedData = {
    count: 0,
    kpm: [],
    avgKpm: [],
    words: []
};

export class KeyAggregator implements IAggregator<IKeyAggregatedData> {
    private kpmFreq: number;
    private kpmCounter: number;
    private eventTime: number;
    private lettersBuffer: string[];
    private wordDelimiters: string[];
    private aggregatedData: IKeyAggregatedData;

    constructor() {
        this.kpmFreq = 60;
        this.kpmCounter = 0;
        this.eventTime = 0;
        this.lettersBuffer = [];
        this.wordDelimiters = ['Enter', 'Space', 'Period', 'Comma']; // keyevent.code
        this.aggregatedData = defaultKeyAggregatedData;
    }

    aggregate(event: EventData): IKeyAggregatedData {
        let eventTime = event.detectedOn.getTime();

        this.aggregateCount();

        this.aggregateKpm(eventTime);

        this.aggregateWord(event);

        return this.aggregatedData;
    }

    getAggregatedData(): IKeyAggregatedData {
        return this.aggregatedData;
    }

    private aggregateCount() {
        this.aggregatedData.count++;
    }

    private aggregateKpm(eventTime: number) {
        const firstKey = this.eventTime === 0;

        if(firstKey) {
            this.eventTime = eventTime;
            return this.aggregatedData;
        }

        this.kpmCounter++;

        const period = (eventTime - this.eventTime)/1000;
        const kpm = this.calcKpm(this.kpmCounter, period);

        Logger.log(`[KeyAggregator]> aggregating ${this.kpmCounter}keys/${period}s ... -> kpm: ${kpm}`);

        this.aggregatedData.kpm.push(kpm);
        this.kpmCounter = 0;
        this.eventTime = eventTime;

        this.aggregateAvgKpm();
    }

    private aggregateAvgKpm() {
        let avgKpm = this.calcAvgKpm();

        this.aggregatedData.avgKpm.push(avgKpm);
    }

    private aggregateWord(event: EventData) {
        const code: string = event.keyCode ? event.keyCode : '';
        const key: string = event.key ? event.key.toLocaleLowerCase() : '';

        if (!key.length || key.length !== 1) {
            return;
        }

        const isLetter: boolean = (key >= "a" && key <= "z");
        //const isNumber: boolean = (key >= "0" && key <= "9");

        if(this.wordDelimiters.indexOf(code) > -1 && this.lettersBuffer.length > 0) {
            let word: string = this.lettersBuffer.join('');

            this.aggregatedData.words.push(word);
            this.lettersBuffer = [];
            
            window.dispatchEvent(new CustomEvent('word'));
        }
        if(isLetter) {
            this.lettersBuffer.push(key);
        }
    }

    private calcKpm(count: number, period: number) {
        const minutes = period/this.kpmFreq;
        const kpm = MathUtils.round(count/minutes, 2);

        return kpm;
    }

    private calcAvgKpm() {
        let kpmSum: number = 0;
        this.aggregatedData.kpm.forEach(function (kpm) {
            kpmSum += kpm;
        });

        const avgKpm = MathUtils.round(kpmSum/this.aggregatedData.kpm.length, 2);

        return avgKpm;
    }
}