import React from "react";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const AppNavbar = (props: { brandText: string }) => {
  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <span className="h4 mb-0 text-white d-lg-inline-block">
          Web Analytics Client <sup>{process.env.NODE_ENV}</sup>
        </span>
        <a
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          href="#/"
        >
          {props.brandText}
        </a>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">Opcje</span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Opcje aplikacji</h6>
              </DropdownItem>
              <DropdownItem>
                <i className="fas fa-bug" />
                <span>Debug</span>
              </DropdownItem>
              <DropdownItem>
                <i className="fas fa-refresh" />
                <span>Reset</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
