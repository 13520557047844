const timestamp = () => {
    let date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

const Logger = {

    log(message: string, data?: any) {

        message = `[${timestamp()}] ${message}`;

        if(data) {
            console.log(message, data);
        } else {
            console.log(message);
        }
    },

    warn(message: string, data?: any) {

        message = `[${timestamp()}] ${message}`;

        if(data) {
            console.warn(message, data);
        } else {
            console.warn(message);
        }
    },

    error(message: string, data?: any) {

        message = `[${timestamp()}] ${message}`;
        
        if(data) {
            console.error(message, data);
        } else {
            console.error(message);
        }
    }
};

export default Logger;