import { browserName, browserVersion, deviceDetect, isDesktop, isMobile, isTablet, mobileModel, osName, osVersion } from "react-device-detect";

export enum DeviceType {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Browser = 'Browser'
}

export interface IDevice {
    type: string,
    name: string,
    os: string
}

export interface IBrowser {
    name: string,
    version: string
}

export interface IScreen {
    width: number,
    height: number
}

export interface IDetectedEnvData {
    device: IDevice,
    browser: IBrowser,
    screen: IScreen
}

export const envDefaultValue: string = 'undetected';

export const defaultDetectedEnvData = {
    device: {
        type: envDefaultValue,
        name: envDefaultValue,
        os: envDefaultValue
    },
    browser: {
        name: envDefaultValue,
        version: envDefaultValue
    },
    screen: {
        width: 0,
        height: 0
    }
};

export class EnvDataDetector {

    detect(): IDetectedEnvData {
        const detected: IDetectedEnvData = {
            device: this.getDevice(),
            browser: this.getBrowser(),
            screen: this.getScreen()
        };

        return detected;
    }

    getDevice(): IDevice {
        const detected: any = deviceDetect();
        const device: IDevice = {
            type: envDefaultValue,
            name: envDefaultValue,
            os: osName ? osName + (osVersion ? ' ' + osVersion : '' ) : envDefaultValue
        };
        
        //device.type = deviceType;
        if(isMobile) {
            device.type = DeviceType.Mobile;
            device.name = mobileModel ? mobileModel : detected.device ? detected.device : device.name;
            //device.os = detected.os;
        } else if(isTablet) {
            device.type = DeviceType.Tablet;
            device.name = detected.device ? detected.device : 'Tablet';
            //device.os = detected.os;
        } else if(isDesktop) {
            device.type = DeviceType.Browser;
            device.name = detected.browser ? detected.browser : 'Desktop';
            //device.os = detected.os ? detected.os : device.os;
        }

        return device;
    }

    getBrowser(): IBrowser {
        const browser: IBrowser = {
            name: browserName,
            version: browserVersion
        };

        return browser;
    }

    getScreen(): IScreen {
        const screen: IScreen = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        return screen;
    }
}